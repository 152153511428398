import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout/Layout';
import Services from './Services';

export default props => {
  const data = {
    servicesPage: props.data.servicesPage.nodes[0],
    progressSteps: props.data.progressSteps.nodes,
    clients: props.data.clients.nodes,
  };
  return (
    <Layout>
      <Services {...props} data={data} />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    servicesPage: allContentfulServicesPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        videoID
        titleImageHighRes {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        claim1 {
          claim1
        }
        clientsHeadline
      }
    }

    progressSteps: allContentfulProgressStep(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        highResImage {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        stepName
        step
        description {
          description
        }
      }
    }

    clients: allContentfulClient(filter: { node_locale: { eq: $locale } }) {
      nodes {
        clientName
        clientLogo {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
