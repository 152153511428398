import React, { Fragment, useState, useEffect } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import {
  ProgressSlider,
  Carousel,
  CarouselScreen,
  Clients,
  Footer,
} from '../../components';
import { sortByKey, getHashSection } from '../../helper/helper';
import FullViewportStage from '../../components/FullViewportStage/FullViewportStage';
import VideoStage from '../../components/VideoStage/VideoStage';
import ScrollTransition from '../../components/Carousel/Transitions/ScrollTransition';
import TransparentTextStage from '../../components/TransparentTextStage/TransparentTextStage';
import ScrollDown from '../../components/ScrollDown/ScrollDown';
import { getLocaleFromPathname } from '../../i18n/helpers';

import './Services.scss';

const AboveTheFold = ({ goToScreen, servicesPage }) => (
  <FullViewportStage centered>
    <FullViewportStage centered>
      <h1 className={'typography typography__header1'}>{servicesPage.title}</h1>
      <ScrollDown onClick={() => goToScreen(1)} />
    </FullViewportStage>
  </FullViewportStage>
);

const LastScreen = ({
  scrollTop,
  scrollToTop,
  goToScreen,
  servicesPage,
  seeAll,
  clients,
  location,
}) => {
  const goToTop = async () => {
    goToScreen(0, true);
  };

  useEffect(() => {
    const hash = location.hash;
    if (hash.length) {
      goToScreen(hash, true, { block: 'center' });
    }
  }, []);

  return (
    <Fragment>
      <FullViewportStage black centered regularOverflow>
        <Clients
          headline={servicesPage.clientsHeadline}
          clients={sortByKey(clients, 'clientName')}
          seeAll={seeAll}
          pathname={location.pathname}
        />
      </FullViewportStage>

      <Footer onArrowClick={() => goToTop()} />
    </Fragment>
  );
};

const staticLayoutBreakpoint = 1024;

const ServicesScrollHijack = props => (
  <div className={'services__blackContainer'}>
    <ReactResizeDetector handleWidth refreshMode={'debounce'}>
      {({ width }) => (
        <ProgressSlider
          progressSteps={sortByKey(props.progressSteps, 'step')}
          hasStaticLayout={width > staticLayoutBreakpoint}
          scrollTop={props.scrollTop}
        />
      )}
    </ReactResizeDetector>
  </div>
);

const Services = props => {
  // TODO sadface for calling a variable "data". everything in computers is "data".
  const { text } = props.pageContext;
  const { seeAll } = text;
  const { location, data } = props;
  const { servicesPage, progressSteps, clients } = data;

  // TODO: localize headings
  return (
    <div>
      <VideoStage
        videoId={servicesPage.videoID}
        videoBackground
        imageBackgroundSrc={servicesPage.titleImageHighRes}
        fixedBackground
      />

      <Carousel transitionTime={0.3} location={location}>
        <CarouselScreen transition={ScrollTransition}>
          <AboveTheFold servicesPage={servicesPage} />
        </CarouselScreen>

        <CarouselScreen
          transition={ScrollTransition}
          transitionOptions={{ lastOfKind: true }}
        >
          <TransparentTextStage
            text={servicesPage.claim1 && servicesPage.claim1.claim1}
          />
        </CarouselScreen>

        <CarouselScreen scrollable={true}>
          <ServicesScrollHijack progressSteps={progressSteps} />
        </CarouselScreen>

        <CarouselScreen scrollable={true}>
          <LastScreen
            clients={clients}
            servicesPage={servicesPage}
            seeAll={seeAll}
            location={location}
          />
        </CarouselScreen>
      </Carousel>
    </div>
  );
};

export default Services;
